//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-679:_7156,_3520,_6468,_2200,_5324,_8504,_2100,_6922;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-679')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-679', "_7156,_3520,_6468,_2200,_5324,_8504,_2100,_6922");
        }
      }catch (ex) {
        console.error(ex);
      }